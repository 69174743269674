import { Button, Stack, TextField, Typography } from "@mui/material";
import { Plus } from "@promaton/icons";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { generateUUID } from "three/src/math/MathUtils";

import { AnnotationRegion } from "../static/AnnotationRegion";
import { AnnotationType } from "../static/AnnotationType";
import {
  AssignmentConfiguration,
  assignmentConfigurationSchema,
  AssignmentStep,
} from "../static/AssignmentConfiguration";
import { getRandomStepColor } from "../utils/getRandomStepColor";
import { AssignmentConfiguratorStep } from "./AssignmentConfiguratorStep";
import { ConfiguredMarkdown } from "./ConfiguredMarkdown";

export const AssignmentConfigurator: FC<{
  value?: object;
  onChange: (config: AssignmentConfiguration) => void;
}> = ({ onChange, value }) => {
  const [steps, setSteps] = useState<AssignmentStep[]>([]);

  const { register, control, setValue } = useForm<{
    instruction: string | undefined;
  }>();

  const instruction = useWatch({ name: "instruction", control });

  const copy = useCallback(
    (step: AssignmentStep) => {
      const stepIndex = steps.findIndex((s) => s.id === step.id);
      if (stepIndex >= 0) {
        const n = [...steps];
        n.splice(stepIndex + 1, 0, { ...step, id: generateUUID() });
        setSteps(n);
      }
    },
    [steps]
  );

  useEffect(() => {
    if (!value) return;
    const config = assignmentConfigurationSchema.safeParse(value);
    if (config.success) {
      setSteps(config.data.steps);
      setValue("instruction", config.data.instruction);
    }
  }, [value]);

  const deleteStep = useCallback(
    (step: AssignmentStep) => {
      const stepIndex = steps.findIndex((s) => s.id === step.id);
      if (stepIndex >= 0) {
        const n = [...steps];
        n.splice(stepIndex, 1);
        setSteps(n);
      }
    },
    [steps]
  );

  const move = useCallback(
    (step: AssignmentStep, increment: number) => {
      const stepIndex = steps.findIndex((s) => s.id === step.id);
      if (stepIndex >= 0) {
        const n = [...steps];
        n.splice(stepIndex, 1);
        n.splice(stepIndex + increment, 0, step);
        setSteps(n);
      }
    },
    [steps]
  );

  const update = useCallback(
    (data: AssignmentStep) => {
      const stepIndex = steps.findIndex((s) => s.id === data.id);
      if (stepIndex >= 0) {
        const n = [...steps];
        n[stepIndex] = data;
        setSteps(n);
      }
    },
    [steps]
  );

  useEffect(() => {
    onChange({ steps, instruction });
  }, [steps, instruction]);

  return (
    <Stack
      sx={{
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack gap={2}>
        <Typography variant="h6">Annotation task configuration</Typography>
        <Stack direction="row" gap={2}>
          <TextField
            {...register("instruction")}
            label="Assignment instructions"
            sx={{ mb: 2, flex: 1 }}
            multiline
            minRows={5}
          />
          <div style={{ flex: 1 }}>
            {!instruction ? (
              <Typography color="textSecondary" sx={{ my: 2 }}>
                Preview will appear here. Use Markdown for formatting.
              </Typography>
            ) : (
              <ConfiguredMarkdown>{instruction}</ConfiguredMarkdown>
            )}
          </div>
        </Stack>

        {steps.map((step, i) => (
          <AssignmentConfiguratorStep
            key={step.id}
            index={i}
            step={step}
            onUpdate={(data) => update(data)}
            onCopy={() => copy(step)}
            onDelete={() => deleteStep(step)}
            onMove={(increment) => move(step, increment)}
          />
        ))}

        <Button
          fullWidth
          startIcon={<Plus />}
          onClick={() => {
            const newConfig: AssignmentStep[] = [
              ...steps,
              {
                id: generateUUID(),
                name: "",
                instruction: "",
                type: AnnotationType.POINT,
                color: getRandomStepColor(),
                perRegion: AnnotationRegion.PER_CASE,
              },
            ];

            setSteps(newConfig);
          }}
        >
          Add Step
        </Button>
      </Stack>
    </Stack>
  );
};
