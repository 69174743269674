import type { ComponentProps } from "react";
import Markdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";

export const ConfiguredMarkdown = (props: ComponentProps<typeof Markdown>) => (
  <Markdown
    {...props}
    remarkPlugins={[remarkGfm, remarkBreaks, ...(props.remarkPlugins ?? [])]}
    rehypePlugins={[
      rehypeRaw,
      [
        rehypeExternalLinks,
        {
          target: "_blank",
          rel: ["nofollow", "noopener", "noreferrer"],
        },
      ],
      ...(props.rehypePlugins ?? []),
    ]}
  />
);
