import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CaretDown1px, CaretLeft } from "@promaton/icons";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "wouter";
import * as z from "zod";

import { AssignmentTypeName } from "../../components/AssignmentCard";
import { AssignmentConfigurator } from "../../components/AssignmentConfigurator";
import FormEditor from "../../components/FormEditor";
import { S3QueryResult, S3Search } from "../../components/S3Search";
import { trpc } from "../../hooks/trpc";
import { AssignmentType } from "../../static/AssignmentType";
import { routes } from "../routes";
import {
  FilterSchema as DatalakeFilterSchema,
  LakehouseSearch,
} from "./../../components/LakehouseSearch";

export const DataSources = {
  LAKEHOUSE_V2: "LAKEHOUSE_V2",
  S3: "S3",
} as const;

export const projectFormSchema = z.object({
  name: z.string().min(5),
  submissionsPerAssignment: z.number().int().min(1).max(20),
  groups: z.array(z.string()).optional(),
  assignmentType: z.nativeEnum(AssignmentType),
  dataSource: z.nativeEnum(DataSources).optional(),
  skipReview: z.boolean().optional(),
  loadAnnotations: z.boolean().optional(),
  automatedLakehouseSyncEnabled: z.boolean().default(false),
  assignmentConfig: z
    .string()
    .refine((s) => {
      try {
        if (!s) return true;
        JSON.parse(s);
        return true;
      } catch {
        return false;
      }
    }, "Invalid JSON")
    .optional(),
  numberOfReviewsPerSubmission: z.number().min(0).default(1),
  userGroupListPerReview: z.array(z.array(z.string())).default([]),
});

type ProjectFormSchema = z.infer<typeof projectFormSchema>;

export const CreateProjectPage = () => {
  const [_, setLocation] = useLocation();

  const createProject = trpc.project.create.useMutation();
  const utils = trpc.useContext();
  const groups = trpc.group.list.useQuery();

  const [config, setConfig] = useState<object>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<ProjectFormSchema>({
    resolver: zodResolver(projectFormSchema),
    defaultValues: {
      submissionsPerAssignment: 1,
      dataSource: DataSources.LAKEHOUSE_V2,
      groups: [],
      numberOfReviewsPerSubmission: 1,
      userGroupListPerReview: [[]],
    },
  });

  const assignmentType = watch("assignmentType");
  const dataSource = watch("dataSource");

  const [datalakeFilter, setDatalakeFilter] = useState<DatalakeFilterSchema>();
  const [s3Query, setS3Query] = useState<S3QueryResult>();
  const searchScans = trpc.scan.queryLakehouse.useQuery(
    {
      ...datalakeFilter,
      dataSource: DataSources.LAKEHOUSE_V2,
    },
    {
      enabled: dataSource === DataSources.LAKEHOUSE_V2,
    }
  );

  useEffect(() => {
    setValue("assignmentConfig", undefined);
    setConfig(undefined);
  }, [assignmentType]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const templates = trpc.template.list.useQuery(
    { type: assignmentType },
    {
      enabled: !!assignmentType,
    }
  );

  const numberOfReviewsPerSubmission = watch("numberOfReviewsPerSubmission", 0);

  useEffect(() => {
    if (!numberOfReviewsPerSubmission) {
      return;
    }

    const currentReviews = getValues("userGroupListPerReview");
    if (!currentReviews) {
      return;
    }

    const newReviews = Array.from(
      { length: numberOfReviewsPerSubmission },
      (_, i) => currentReviews[i] || []
    );
    setValue("userGroupListPerReview", newReviews);
  }, [numberOfReviewsPerSubmission]);

  return (
    <Container sx={{ marginY: 2 }} maxWidth={"xl"}>
      {createProject.isLoading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, zIndex: 10 }}
        />
      )}

      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Link href={routes.adminRoot}>
          <IconButton>
            <CaretLeft />
          </IconButton>
        </Link>
        <Typography variant="h4" fontWeight={"bold"} mt={3} mb={3} flex={1}>
          New project
        </Typography>
      </Stack>
      <Typography variant="h6" mb={2}>
        Select data
      </Typography>
      <FormControl fullWidth error={!!errors.dataSource} sx={{ mb: 2 }}>
        <InputLabel>Data Source</InputLabel>
        <Select
          {...register("dataSource", { required: true })}
          value={dataSource}
          error={!!errors.dataSource}
          label={"Data Source"}
        >
          {Object.values(DataSources).map((option) => (
            <MenuItem key={option} value={option}>
              {option.split("_").join(" ")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {dataSource === DataSources.LAKEHOUSE_V2 && (
        <LakehouseSearch
          filter={datalakeFilter}
          onChange={setDatalakeFilter}
          data={searchScans.data}
          loading={searchScans.isLoading}
        />
      )}
      {dataSource === DataSources.S3 && <S3Search onChange={setS3Query} />}

      <form
        onSubmit={handleSubmit(async (d) => {
          if (!dataSource) return;

          const query =
            dataSource === DataSources.LAKEHOUSE_V2
              ? { dataSource, ...datalakeFilter }
              : { dataSource, resourceLocation: "", slugs: [], ...s3Query };
          await createProject.mutateAsync({
            name: d.name,
            assignmentType: d.assignmentType,
            scanQuery: query,
            submissionsPerAssignment: d.submissionsPerAssignment,
            groups: d.groups,
            skipReview: d.skipReview,
            loadAnnotations: d.loadAnnotations,
            automatedLakehouseSyncEnabled: d.automatedLakehouseSyncEnabled,
            assignmentConfig: d.assignmentConfig
              ? JSON.parse(d.assignmentConfig)
              : undefined,
            numberOfReviewsPerSubmission: d.numberOfReviewsPerSubmission,
            userGroupListPerReview: d.userGroupListPerReview,
          });

          utils.project.invalidate();
          utils.assignment.invalidate();
          utils.submission.invalidate();

          setLocation(routes.adminRoot);
        })}
      >
        <Stack alignItems={"flex-start"} gap={3} pb={3}>
          <Typography variant="h6">Project details</Typography>
          <TextField
            error={!!errors.name}
            helperText={errors.name?.message as string}
            fullWidth
            autoComplete="off"
            label="Project name"
            {...register("name")}
          />
          <TextField
            error={!!errors.submissionsPerAssignment}
            helperText={errors.submissionsPerAssignment?.message as string}
            fullWidth
            type="number"
            label="Target number of submissions per assignment"
            {...register("submissionsPerAssignment", { valueAsNumber: true })}
          />
          <Stack>
            <FormControlLabel
              control={<Checkbox {...register("skipReview")} />}
              label="Skip reviews (assignments are automatically approved)"
            />

            {dataSource === DataSources.LAKEHOUSE_V2 && (
              <>
                <FormControlLabel
                  control={<Checkbox {...register("loadAnnotations")} />}
                  label="Load existing annotations from lakehouse"
                />
                <FormControlLabel
                  control={
                    <Checkbox {...register("automatedLakehouseSyncEnabled")} />
                  }
                  label="Enable automated sync of new annotations to lakehouse"
                />
              </>
            )}
          </Stack>

          <Typography variant="h6">Assignment</Typography>
          <Card sx={{ width: "100%" }} variant="outlined">
            <CardContent>
              <Stack gap={3} sx={{ width: "100%" }} alignItems="flex-start">
                <FormControl fullWidth error={!!errors.assignmentType}>
                  <InputLabel>Project type</InputLabel>
                  <Select
                    {...register("assignmentType")}
                    error={!!errors.assignmentType}
                    label={"Project type"}
                  >
                    {Object.values(AssignmentType).map((option) => (
                      <MenuItem key={option} value={option}>
                        {AssignmentTypeName[option]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {assignmentType && (
                  <>
                    <Button
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                      color="secondary"
                      disabled={templates.isLoading}
                      variant="outlined"
                      endIcon={<CaretDown1px />}
                    >
                      Apply template
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={!!anchorEl}
                      onClose={() => setAnchorEl(null)}
                    >
                      {templates.data?.map((t) => (
                        <MenuItem
                          key={t.id}
                          onClick={() => {
                            setAnchorEl(null);
                            setValue(
                              "assignmentConfig",
                              JSON.stringify(t.data)
                            );
                            typeof t.data === "object" &&
                              setConfig(t.data as object);
                          }}
                        >
                          {t.name}
                        </MenuItem>
                      ))}
                      {templates.data?.length === 0 && (
                        <MenuItem disabled>No templates available</MenuItem>
                      )}
                    </Menu>
                  </>
                )}

                {assignmentType === AssignmentType.SURVEY && (
                  <FormEditor
                    data={config}
                    errorText={errors.assignmentConfig?.message || ""}
                    onRefresh={() => {
                      const form = getValues("assignmentConfig");
                      setConfig(form ? JSON.parse(form) : {});
                    }}
                    {...register("assignmentConfig")}
                  />
                )}

                {assignmentType === AssignmentType.ANNOTATION && (
                  <AssignmentConfigurator
                    value={config}
                    onChange={(v) => {
                      setValue("assignmentConfig", JSON.stringify(v));
                    }}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
          <Typography variant="h6">Review</Typography>
          <Card sx={{ width: "100%" }} variant="outlined">
            <CardContent>
              <Stack gap={3} sx={{ width: "100%" }} alignItems="flex-start">
                <Typography variant="h6">Review management</Typography>
                <Typography>
                  Set the number of reviews per submission and the groups that
                  will review the submissions.
                </Typography>
                <TextField
                  disabled={watch("skipReview")}
                  error={!!errors.numberOfReviewsPerSubmission}
                  helperText={
                    errors.numberOfReviewsPerSubmission?.message as string
                  }
                  fullWidth
                  type="number"
                  label="Number of reviews per submission"
                  {...register("numberOfReviewsPerSubmission", {
                    valueAsNumber: true,
                  })}
                />
                <Typography>
                  If no group is selected, any reviewer can review.
                </Typography>
                {Array.from({
                  length: numberOfReviewsPerSubmission || 0,
                }).map((_, index) =>
                  groups.data && groups.data.length > 0 ? (
                    <Autocomplete
                      key={index}
                      disabled={watch("skipReview")}
                      multiple
                      fullWidth
                      {...register(`userGroupListPerReview.${index}`)}
                      onChange={(_, v) => {
                        setValue(`userGroupListPerReview.${index}`, v);
                      }}
                      options={groups.data?.map((option) => option.name) || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Review groups ${index + 1}`}
                          placeholder="Groups"
                        />
                      )}
                    />
                  ) : (
                    <Alert severity="warning" key={index}>
                      Please first create a User Group!
                    </Alert>
                  )
                )}
              </Stack>
            </CardContent>
          </Card>
          <Stack gap={2} my={1} sx={{ width: "100%" }}>
            <Typography variant="h6">Access management</Typography>
            <Typography>
              Add groups that can access the project below. If empty, any group
              can access. Admins can always access.
            </Typography>
            <Autocomplete
              {...register("groups")}
              multiple
              fullWidth
              onChange={(_, v) => {
                setValue("groups", v);
              }}
              options={groups.data?.map((option) => option.name) || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Limit access to groups"
                  placeholder="Groups"
                />
              )}
            />
          </Stack>

          <Button
            disabled={searchScans.isLoading || createProject.isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            Create
          </Button>
        </Stack>
      </form>
    </Container>
  );
};
