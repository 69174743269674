import {
  Button,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Trash } from "@promaton/icons";
import { FC, forwardRef, memo } from "react";
import { useForm } from "react-hook-form";
import { TableVirtuoso } from "react-virtuoso";
import { z } from "zod";

import { APIOutput, trpc } from "../hooks/trpc";
import { Role } from "../hooks/useRole";
import { EmptyState } from "./EmptyState";

type Invite = APIOutput["user"]["listInvites"][0];

export const AdminInviteTable: FC<{}> = () => {
  const { register, handleSubmit, watch, reset } = useForm<{
    addUsers: string;
    role: Role;
  }>({
    defaultValues: {
      role: Role.ANNOTATOR,
    },
  });

  const role = watch("role");
  const invites = trpc.user.listInvites.useQuery({});
  const inviteUsers = trpc.user.inviteUsers.useMutation();
  const utils = trpc.useContext();

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          const emailParser = z.string().email();
          const emails = data.addUsers.split(/[ ,;]+/).filter((s) => {
            return emailParser.safeParse(s).success;
          });
          const emailSet = new Set(emails);
          await inviteUsers.mutateAsync({
            role: data.role,
            emails: Array.from(emailSet),
          });
          utils.user.listInvites.invalidate();
          reset();
        })}
      >
        <Stack flexDirection={"row"} gap={2} mb={3}>
          <InputBase
            placeholder="Add users (comma or space separated emails)"
            autoComplete="off"
            {...register("addUsers", {
              minLength: 5,
            })}
            fullWidth
            sx={{
              flex: 1,
              padding: 1,
              paddingLeft: 2,
              background: (t) => t.palette.action.hover,
              borderRadius: (t) => t.spacing(1),
            }}
          />
          <Select value={role} sx={{ width: 150 }} {...register("role")}>
            <MenuItem value={Role.ANNOTATOR}>Annotator</MenuItem>
            <MenuItem value={Role.REVIEWER}>Reviewer</MenuItem>
            <MenuItem value={Role.ADMIN}>Admin</MenuItem>
          </Select>
          <Button variant="contained" type="submit">
            Add
          </Button>
        </Stack>
      </form>
      {(invites.data?.length || undefined) && (
        <TableVirtuoso
          useWindowScroll
          components={{
            Scroller: forwardRef((props, ref) => (
              <div {...props} ref={ref}></div>
            )),
            Table: (props) => (
              <Table {...props} style={{ borderCollapse: "separate" }} />
            ),
            TableHead: forwardRef((props, ref) => (
              <TableHead ref={ref} {...props} sx={{ top: "74px !important" }} />
            )),
            TableRow: (props) => (
              <TableRow
                {...props}
                sx={{
                  ["& td"]: {
                    borderBottomColor: (t) => t.palette.divider,
                  },
                  ["&:not(:hover) td:last-child button"]: {
                    opacity: 0,
                  },
                }}
              />
            ),
            TableBody: forwardRef((props, ref) => (
              <TableBody {...props} ref={ref} />
            )),
          }}
          style={{
            flex: "0 0 auto",
            height: 100,
            width: "100%",
          }}
          totalCount={invites.data?.length}
          data={invites.data}
          fixedHeaderContent={() => (
            <TableRow
              sx={{
                background: (t) => t.palette.background.default,

                ["& th"]: {
                  borderBottomColor: (t) => t.palette.divider,
                },
              }}
            >
              <TableCell sx={{ paddingLeft: 0 }}>Email</TableCell>
              <TableCell align="left" sx={{ width: 150 }}>
                Role
              </TableCell>
              <TableCell sx={{ width: 50 }}></TableCell>
            </TableRow>
          )}
          itemContent={(index, user) =>
            user ? <InviteTableRow invite={user} key={user.id} /> : null
          }
        />
      )}
      {invites.data?.length === 0 && (
        <EmptyState>
          <Typography>No open invitations</Typography>
        </EmptyState>
      )}
    </>
  );
};

export const InviteTableRow: FC<{ invite: Invite }> = memo(({ invite }) => {
  const utils = trpc.useContext();
  const updateInvite = trpc.user.updateInvite.useMutation();
  const deleteInvite = trpc.user.deleteInvite.useMutation();

  return (
    <>
      <TableCell sx={{ paddingLeft: 0 }}>{invite.email}</TableCell>
      <TableCell align="left">
        <Select
          size="small"
          value={invite.role}
          fullWidth
          disabled={updateInvite.isLoading}
          onChange={async (_, value) => {
            console.log(_, value);
            await updateInvite.mutateAsync({
              email: invite.email,
              role: _.target.value as Role,
            });
            utils.user.listInvites.invalidate();
          }}
        >
          <MenuItem value={Role.ANNOTATOR}>Annotator</MenuItem>
          <MenuItem value={Role.REVIEWER}>Reviewer</MenuItem>
          <MenuItem value={Role.ADMIN}>Admin</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right">
        <IconButton
          disabled={deleteInvite.isLoading}
          onClick={async () => {
            await deleteInvite.mutateAsync({ email: invite.email });
            utils.user.listInvites.invalidate();
          }}
        >
          <Trash />
        </IconButton>
      </TableCell>
    </>
  );
});
